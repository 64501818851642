import React from "react"
import { Segment, SubHeading, Anchor } from "./section.styles"

import Markdown from "../markdown"

const Section = ({ heading, body }) => (
  <Segment>
    {heading && <Anchor id={heading.replace(" ", "-").toLowerCase()} />}
    {heading && <SubHeading>{heading}</SubHeading>}
    <div>
      <Markdown>{body}</Markdown>
    </div>
  </Segment>
)

export default Section
