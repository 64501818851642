import styled from "@emotion/styled"
import { darkGrey, logoOrange, Fira, Gloria, offWhite } from "../../globalCss"

export const Segment = styled.section`
  ${Fira}
  color: ${darkGrey};
  border-left: ${darkGrey} 1px solid;
  border-right: ${darkGrey} 1px solid;
  background-color: ${offWhite};
  font-size: 2rem;
  padding: 2rem 5rem;
  line-height: 1.4;

  h1 {
    font-size: 2.5rem;
    font-weight: 600;
  }

  h2 {
    font-size: 2.2rem;
    font-weight: 600;
  }

`
export const SubHeading = styled.h3`
  ${Gloria}
  margin-bottom: 2rem;
  font-size: 3rem;
  color: ${logoOrange};
`
export const Anchor = styled.a`
  display: block;
  position: relative;
  top: -11.2rem;
  visibility: hidden;
`
