import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { Shroud, ResponsiveBackgroundImage } from "./backgroundImage.styles"

const BackgroundImage = ({ src, alt, children }) => (
  <>
    <ResponsiveBackgroundImage src={src} alt={alt}>
      <Shroud>{children}</Shroud>
    </ResponsiveBackgroundImage>
  </>
)

export default BackgroundImage
