import styled from "@emotion/styled"
import { css } from "@emotion/core"

const retinaQuery = `@media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx)`

const breakpoints = [1000, 800, 600, 400]

const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`)

export const ResponsiveBackgroundImage = styled.div`
  min-height: 40rem;
  background-size: cover;
  background-position: 51% center;
  background-repeat: no-repeat;
  background-origin: border-box;
  background-clip: content-box;
  text-align: center;
  margin: auto;
  padding: 0;
  background-image: ${props =>
    `url("${props.src}-/scale_crop/1200x400/center/-/format/auto/");`};
  ${retinaQuery} {
    background-image: ${props =>
      `url("${props.src}-/scale_crop/2400x800/center/-/format/auto/");`};
  }
  ${mq[0]} {
    background-image: ${props =>
      `url("${props.src}-/scale_crop/1000x400/center/-/format/auto/");`};
    ${retinaQuery} {
      background-image: ${props =>
        `url("${props.src}-/scale_crop/2000x800/center/-/format/auto/");`};
    }
  }
  ${mq[1]} {
    background-image: ${props =>
      `url("${props.src}-/scale_crop/800x400/center/-/format/auto/");`};
    ${retinaQuery} {
      background-image: ${props =>
        `url("${props.src}-/scale_crop/1600x800/center/-/format/auto/");`};
    }
  }
  ${mq[2]} {
    background-image: ${props =>
      `url("${props.src}-/scale_crop/600x400/center/-/format/auto/");`};
    ${retinaQuery} {
      background-image: ${props =>
        `url("${props.src}-/scale_crop/1200x800/center/-/format/auto/");`};
    }
  }
  ${mq[3]} {
    background-image: ${props =>
      `url("${props.src}-/scale_crop/400x400/center/-/format/auto/");`};
    ${retinaQuery} {
      background-image: ${props =>
        `url("${props.src}-/scale_crop/800x800/center/-/format/auto/");`};
    }
  }
`

export const Shroud = styled.div`
  min-height: 40rem;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
