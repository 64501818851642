import React, { useMemo } from "react"
import { Remarkable } from "remarkable"
var md = new Remarkable()

const Markdown = ({ children }) => {
  return (
    <>
      {" "}
      {children ? (
        <div dangerouslySetInnerHTML={{ __html: md.render(children) }} />
      ) : null}
    </>
  )
}

export default Markdown
